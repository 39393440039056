import type {FC} from 'react';
import clsx from 'clsx';
import Image from 'next/image';

import type {ContainerProps} from '@/components/container';
import {Container} from '@/components/container';
import {Heading} from '@/components/typography/heading';
import type {KeyedArray} from '@/shared/types';

import type {FeaturedCardProps} from '../featured-card';
import {FeaturedCard} from '../featured-card';

export type FeaturedCardListProps = ContainerProps & {
	heading?: string;
	cards?: KeyedArray<FeaturedCardProps>;
};

export const FeaturedCardList: FC<FeaturedCardListProps> = ({
	heading,
	cards,
	...containerProps
}) => {
	return (
		<Container {...containerProps}>
			{heading && (
				<div className={clsx('max-w-[605px]', 'mx-auto', 'text-center', 'mb-12')}>
					<Heading level={2} spacing>
						{removeLastWordInString(heading)}{' '}
						<span className={clsx('relative')}>
							{lastWordInString(heading)}
							<div
								className={clsx(
									'absolute',
									'left-full',
									'top-3',
									'size-[90px]',
									'lg:size-[100px]',
								)}
							>
								<Image
									src="/spiral.svg"
									fill
									style={{objectFit: 'contain'}}
									alt=""
								/>
							</div>
						</span>
					</Heading>
				</div>
			)}

			{cards && (
				<ul
					className={clsx(
						'grid',
						'md:grid-cols-2',
						cards.length > 2 ? 'lg:grid-cols-3' : '',
						'gap-6',
					)}
				>
					{cards?.map((card, index) => (
						<li
							key={card._key}
							className={clsx(
								index === 0 && cards.length > 2 && 'max-lg:col-span-full',
							)}
						>
							<FeaturedCard headingLevel={heading ? 3 : 2} {...card} />
						</li>
					))}
				</ul>
			)}
		</Container>
	);
};

const lastWordInString = (str: string) => {
	const words = str.split(' ');
	return words[words.length - 1];
};

const removeLastWordInString = (str: string) => {
	const words = str.split(' ');
	words.pop();
	return words.join(' ');
};
