import type {FC} from 'react';
import clsx from 'clsx';

import type {ContainerBackgroundColor, ContainerSpacing} from '@/components/container';
import {Container} from '@/components/container';
import {Heading} from '@/components/typography/heading';
import {Paragraph} from '@/components/typography/paragraph';
import type {KeyedArray} from '@/shared/types';

import type {Person} from '../../shared/types';
import {PersonCard} from '../person-card';

interface Props {
	people: KeyedArray<Person>;
	title?: string;
	tagline?: string;
	spacingTop?: ContainerSpacing;
	spacingBottom?: ContainerSpacing;
	backgroundColor?: ContainerBackgroundColor;
}

const PersonList: FC<Props> = ({
	people,
	spacingBottom,
	spacingTop,
	title,
	tagline,
	backgroundColor,
}) => {
	return (
		<Container
			spacingBottom={spacingBottom}
			spacingTop={spacingTop}
			backgroundColor={backgroundColor}
		>
			{title && (
				<Heading level={2} spacing>
					{title}
				</Heading>
			)}
			{tagline && <Paragraph>{tagline}</Paragraph>}

			<ul
				className={clsx(
					'grid',
					'grid-cols-1',
					'sm:grid-cols-2',
					'md:grid-cols-3',
					'gap-x-10',
					'gap-y-16',
				)}
			>
				{people.map((person) => {
					return (
						<li key={person._key}>
							<PersonCard person={person} />
						</li>
					);
				})}
			</ul>
		</Container>
	);
};

export default PersonList;
