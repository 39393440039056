import type {FC} from 'react';
import clsx from 'clsx';

import {Badge} from '@/components/badge';
import ImageFill from '@/components/image/ImageFill';
import type {ImageWeb} from '@/components/image/types';
import {Link} from '@/components/link';
import {Heading} from '@/components/typography/heading';
import {Paragraph} from '@/components/typography/paragraph';

type Props = {
	heading?: string;
	imageWeb?: ImageWeb;
	imageAlignment?: 'left' | 'right';
	introduction?: string;
	href?: string;
};

export const CustomerCard: FC<Props> = ({
	heading,
	imageWeb,
	imageAlignment,
	introduction,
	href,
}) => {
	return (
		<div className={clsx('grid', 'lg:grid-cols-3', 'gap-y-6', 'gap-x-10')}>
			<div
				className={clsx(
					'flex',
					'items-center',
					'lg:py-10',
					imageAlignment === 'left' ? 'order-1' : 'order-0 max-lg:order-1',
				)}
			>
				<div>
					{heading && (
						<Heading
							level={2}
							size="2xlarge"
							className={clsx('mb-4', 'font-medium', 'text-black')}
						>
							{heading}
						</Heading>
					)}
					{introduction && <Paragraph>{introduction}</Paragraph>}
					{href && (
						<Link href={href} className={clsx('inline-block', 'mt-4')}>
							Se hva vi har gjort for {heading}
						</Link>
					)}
				</div>
			</div>

			<div className={clsx('relative', 'col-span-2', 'min-h-[300px]')}>
				<Badge className={clsx('z-10', 'absolute', 'left-4', 'bottom-4')}>Kundecase</Badge>

				{imageWeb && (
					<ImageFill
						src={imageWeb.image.src}
						width={imageWeb.image.width}
						height={imageWeb.image.height}
						hotspot={imageWeb.image.hotspot}
						alt={imageWeb.altText ?? ''}
						className={clsx('rounded-xl')}
					/>
				)}
			</div>
		</div>
	);
};
