import {groq} from 'next-sanity';

import type {ImageWeb} from '@/components/image/types';
import {imageWebFromImageWebSchema} from '@/sanity/components/image/query';

import type {Person} from '../shared/types';

import type {PersonSchema} from './schema';

export const PERSON_IN_CARD_FRAGMENT = groq`
	_id,
	name,
	role,
	image,
	email,
	phone
`;

export type PersonInCardFragment = Pick<
	PersonSchema,
	'_id' | 'name' | 'role' | 'image' | 'email' | 'phone'
>;

const imageWeb: ImageWeb = {
	image: {
		src: '/person/silhouette-4-3.png',
		width: 2134,
		height: 1600,
	},
	altText: 'Bilde av en person',
};

export const personFromPersonInCardFragment = (personInCard: PersonInCardFragment): Person => {
	return {
		name: personInCard?.name ?? '',
		image: !personInCard?.image?.asset
			? imageWeb
			: imageWebFromImageWebSchema(personInCard.image, 1),
		phone: personInCard?.phone,
		email: personInCard?.email,
		role: personInCard?.role,
	};
};
