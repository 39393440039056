import type {ComponentPropsWithoutRef} from 'react';
import {forwardRef} from 'react';
import {cva, type VariantProps} from 'class-variance-authority';

import {cn} from '@/shared';

const badgeStyles = cva(
	cn(
		'inline-flex',
		'items-center',
		'justify-center',
		'shrink-0',
		'rounded-full',
		'uppercase',
		'tracking-[1.14px]',
		'text-xs',
		'font-bold',
		'px-[15px]',
		'pt-[6px]',
		'pb-[3px]',
	),
	{
		variants: {
			intent: {
				primary: ['bg-primary', 'text-primary-light'],
				secondary: ['bg-secondary', 'text-primary'],
				tertiary: ['bg-primary-light', 'text-primary'],
			},
		},
		compoundVariants: [{intent: 'primary'}],
		defaultVariants: {
			intent: 'primary',
		},
	},
);

export type BadgeProps = VariantProps<typeof badgeStyles> & ComponentPropsWithoutRef<'div'>;

export const Badge = forwardRef<HTMLSpanElement, BadgeProps>(
	({children, intent = 'primary', className, ...rest}, ref) => {
		return (
			<span ref={ref} className={cn(badgeStyles({intent}), className)} {...rest}>
				{children}
			</span>
		);
	},
);

Badge.displayName = 'Badge';
