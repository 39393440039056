import {toPlainText} from '@portabletext/react';
import {groq} from 'next-sanity';

import {imageWebFromImageWebSchema} from '@/sanity/components/image/query';
import {internalLinkToHref} from '@/shared';

import type {CustomerDetails} from '../shared/types';

import type {CustomerSchema} from './schema';

export const CUSTOMER_IN_CARD_FRAGMENT = groq`
	_id,
	title,
	slug,
	_createdAt,
	image,
	layout,
	introduction
`;

export type CustomerInCardFragment = Pick<
	CustomerSchema,
	'_id' | 'title' | 'slug' | 'image' | 'introduction'
>;

export function customerInCardFragmentToCustomerDetails(
	customer: CustomerInCardFragment,
): CustomerDetails {
	return {
		heading: customer.title,
		href: internalLinkToHref('customer', customer.slug?.current),
		imageWeb: customer?.image?.asset ? imageWebFromImageWebSchema(customer.image) : undefined,
		introduction: customer.introduction ? toPlainText(customer.introduction) : '',
	};
}
