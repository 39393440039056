import type {FC} from 'react';
import React from 'react';
import type {FieldError, UseFormRegister} from 'react-hook-form';
import {FiAlertCircle} from 'react-icons/fi';
import clsx from 'clsx';

import type {FormData} from '../form';

type FormFieldProps = {
	type: string;
	placeholder?: string;
	name: ValidFieldNames;
	label: string;
	register?: UseFormRegister<FormData>;
	error?: FieldError;
	valueAsNumber?: boolean;
	required?: boolean;
};

export type ValidFieldNames = 'name' | 'email' | 'message' | 'phone' | 'businessName';

export const FormField: FC<FormFieldProps> = ({
	type,
	placeholder,
	name,
	label,
	register,
	error,
	valueAsNumber,
	required,
}) => {
	if (type === 'textarea') {
		return (
			<div className={clsx('grid')}>
				<label htmlFor={name} className="font-medium">
					{label}
					{required && <span className="text-red-600">*</span>}
				</label>
				<textarea
					id={name}
					placeholder={placeholder}
					{...(required && {'aria-required': true})}
					{...(error && {'aria-invalid': true})}
					{...(register && register(name, {valueAsNumber}))}
					className={clsx(
						'focus-visible:border-primary',
						'bg-[#F6F8FA]',
						'border',
						'border-[#CBD6E2]',
						'rounded-lg',
						error && 'border-red-600',
					)}
				/>
				{error && (
					<span role="alert" className={clsx('flex', 'gap-1', 'mt-1')}>
						<FiAlertCircle aria-hidden className="mt-[2px] text-red-600" />
						{error.message}
					</span>
				)}
			</div>
		);
	}
	return (
		<div className={clsx('grid')}>
			<label htmlFor={name} className="font-medium">
				{label}
				{required && <span className="text-red-600">*</span>}
			</label>
			<input
				id={name}
				type={type}
				placeholder={placeholder}
				{...(required && {'aria-required': true})}
				{...(error && {'aria-invalid': true})}
				{...(register && register(name, {valueAsNumber}))}
				className={clsx(
					'focus-visible:border-primary',
					'bg-[#F6F8FA]',
					'border',
					'border-[#CBD6E2]',
					'rounded-lg',
					error && 'border-red-600',
				)}
			/>
			{error && (
				<span role="alert" className={clsx('flex', 'gap-1', 'mt-1')}>
					<FiAlertCircle aria-hidden className="mt-[2px] text-red-600" />
					{error.message}
				</span>
			)}
		</div>
	);
};
