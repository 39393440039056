import {groq} from 'next-sanity';

import type {ImageWebSchema} from '@/sanity/schemas/primitives/image-web';
import type {LinkFragment} from '@/sanity/schemas/primitives/link';
import {LINK_FRAGMENT} from '@/sanity/schemas/primitives/link';

import type {LinkListSelectionSchema} from './schema';

export const LINK_LIST_SELECTION_FRAGMENT = groq`
	...,
	links[]{
		...,
		${LINK_FRAGMENT}
	}
`;

export type LinkFragmentType = LinkFragment & {
	_key: string;
	title?: string;
	image?: ImageWebSchema;
	description?: string;
	linkText?: string;
};

export type LinkListSelectionFragmentType = Omit<LinkListSelectionSchema, 'links'> & {
	links?: LinkFragmentType[];
};
