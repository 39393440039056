import type {PropsWithChildren} from 'react';
import {groq} from 'next-sanity';

import type {ImageWebSchema} from '@/sanity/schemas/primitives/image-web';
import type {LinkFragment} from '@/sanity/schemas/primitives/link';
import {LINK_FRAGMENT} from '@/sanity/schemas/primitives/link';

import type {SanityArray} from './../../../sanity/lib/types';
import type {CardDescriptionSchema, FeaturedCardListSchema} from './schema';

export const FEATURED_CARD_LIST_FRAGMENT = groq`
	...,
	cards[]{
		...,
		${LINK_FRAGMENT}
	}
`;

export type CardFragmentType = LinkFragment &
	PropsWithChildren & {
		title?: string;
		tagline?: string;
		icon?: ImageWebSchema;
		description?: CardDescriptionSchema;
	};

export type FeaturedCardListFragment = Omit<FeaturedCardListSchema, 'cards'> & {
	cards?: SanityArray<CardFragmentType>;
};
