import React from 'react';
import clsx from 'clsx';

type ResponseMessageProps = {
	success: boolean;
	errorMessage: string | null;
	successMessage: string | null;
};

export const ResponseMessage = ({success, errorMessage, successMessage}: ResponseMessageProps) => {
	return (
		<div
			className={clsx(
				'border',
				'py-6',
				'px-8',
				'rounded-lg',
				success ? ['border-green-600', 'bg-green-50'] : ['border-red-600', 'bg-red-50'],
			)}
		>
			{success ? (
				<p className={clsx('text-green-700')} role="status">
					{successMessage}
				</p>
			) : (
				<p className={clsx('text-red-700')} role="status">
					{errorMessage}
				</p>
			)}
		</div>
	);
};
