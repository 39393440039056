import {groq} from 'next-sanity';

import type {KeyedArray} from '@/shared/types';

import type {CustomerDetails} from '../../shared/types';
import type {CustomerInCardFragment} from '../query';
import {CUSTOMER_IN_CARD_FRAGMENT, customerInCardFragmentToCustomerDetails} from '../query';
import {customer} from '../schema';

import type {CustomerListAllSchema} from './schema';

export const CUSTOMER_LIST_ALL_FRAGMENT = groq`
	...,
	orderBy == "publishDateNewestFirst" => {
		"customers": *[_type == "${customer().name}"] | order(_createdAt desc) {
			${CUSTOMER_IN_CARD_FRAGMENT}
		}
	},
	orderBy == "publishDateOldestFirst" => {
		"customers": *[_type == "${customer().name}"] | order(_createdAt asc) {
			${CUSTOMER_IN_CARD_FRAGMENT}
		}
	},
	orderBy == "titleAtoZ" => {
		"customers": *[_type == "${customer().name}"] | order(lower(title) asc) {
			${CUSTOMER_IN_CARD_FRAGMENT}
		}
	},
	orderBy == "titleZtoA" => {
		"customers": *[_type == "${customer().name}"] | order(lower(title) desc) {
			${CUSTOMER_IN_CARD_FRAGMENT}
		}
	},
	!defined(orderBy) => {
		"customers": *[_type == "${customer().name}"] | order(_createdAt desc) {
			${CUSTOMER_IN_CARD_FRAGMENT}
		}
	}
`;

export type CustomerListAllFragment = CustomerListAllSchema & {
	_type: 'customerListAll';
	customers: CustomerInCardFragment[];
};

export const customerListFromCustomerListAllSection = (
	section: CustomerListAllFragment,
): KeyedArray<CustomerDetails> => {
	const customers: KeyedArray<CustomerDetails> = [];

	if (!section.customers?.length) {
		return customers;
	}

	for (const customerInCard of section.customers) {
		if (customerInCard) {
			const customer = customerInCardFragmentToCustomerDetails(customerInCard);
			if (customer) {
				customers.push({
					_key: customerInCard._id,
					...customer,
				});
			}
		}
	}

	return customers;
};
