import type {FC} from 'react';
import React from 'react';

import ArticleListPaginated from '../../components/article-list-paginated';

import {type ArticleListAllFragment, articleListFromArticleListAllSection} from './query';

type Props = {
	data: ArticleListAllFragment;
};

export const ArticleListAllContainer: FC<Props> = ({data}) => {
	const articles = articleListFromArticleListAllSection(data);

	return (
		<ArticleListPaginated
			articles={articles}
			limit={data.limit}
			spacingBottom={data.layout?.spacingBottom}
			spacingTop={data.layout?.spacingTop}
			backgroundColor={data.backgroundColor}
		/>
	);
};
