import type {FC} from 'react';
import React from 'react';

import type {ContainerBackgroundColor, ContainerSpacing} from '@/components/container';
import {Container} from '@/components/container';

import {Form} from '../form';

interface Props {
	spacingTop?: ContainerSpacing;
	spacingBottom?: ContainerSpacing;
	backgroundColor?: ContainerBackgroundColor;
}

export const ContactFormSection: FC<Props> = ({spacingBottom, spacingTop, backgroundColor}) => {
	return (
		<Container
			spacingBottom={spacingBottom}
			spacingTop={spacingTop}
			backgroundColor={backgroundColor}
		>
			<Form />
		</Container>
	);
};
