import type {FC} from 'react';

import {CustomerList} from '../../components/customer-list';
import type {CustomerListSelectionFragment} from '../../components/customer-list/query';
import {customerListFromCustomerListSelectionSection} from '../../components/customer-list/query';

type Props = {
	data: CustomerListSelectionFragment;
};

export const CustomerListSelectionContainer: FC<Props> = ({data}) => {
	const customers = customerListFromCustomerListSelectionSection(data);

	return (
		<CustomerList
			customers={customers}
			backgroundColor={data.backgroundColor}
			spacingBottom={data.layout?.spacingBottom}
			spacingTop={data.layout?.spacingTop}
		/>
	);
};
