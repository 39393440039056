import type {FC} from 'react';

import {CustomerList} from '../../components/customer-list';

import {type CustomerListAllFragment, customerListFromCustomerListAllSection} from './query';

type Props = {
	data: CustomerListAllFragment;
};

export const CustomerListAllContainer: FC<Props> = ({data}) => {
	const customers = customerListFromCustomerListAllSection(data);

	return (
		<CustomerList
			customers={customers}
			backgroundColor={data.backgroundColor}
			spacingBottom={data.layout?.spacingBottom}
			spacingTop={data.layout?.spacingTop}
		/>
	);
};
