'use client';

import {type FC, useCallback, useState} from 'react';
import clsx from 'clsx';

import type {ContainerBackgroundColor, ContainerSpacing} from '@/components/container';
import {Container} from '@/components/container';
import type {KeyedArray} from '@/shared/types';

import type {Article} from '../../shared/types';
import {ArticleCard} from '../article-card';

interface Props {
	spacingTop?: ContainerSpacing;
	spacingBottom?: ContainerSpacing;
	limit?: number;
	articles: KeyedArray<Article>;
	backgroundColor?: ContainerBackgroundColor;
}

const ARTICLES_LENGTH = 6;

const ArticleListPaginated: FC<Props> = ({
	spacingBottom,
	spacingTop,
	limit,
	articles,
	backgroundColor,
}) => {
	const [visibleArticles, setVisibleArticles] = useState<KeyedArray<Article>>(
		articles.slice(0, ARTICLES_LENGTH),
	);

	const loadMore = useCallback(() => {
		const nextVisibleArticles = articles.slice(0, visibleArticles?.length + ARTICLES_LENGTH);
		setVisibleArticles(nextVisibleArticles);
	}, [articles, visibleArticles]);

	if (!visibleArticles) return null;

	const totalArticles = limit || articles?.length;

	const hasMore = visibleArticles.length < totalArticles;

	let renderedArticles = visibleArticles;

	renderedArticles = limit && limit > 0 ? visibleArticles.slice(0, limit) : visibleArticles;

	return (
		<Container
			spacingBottom={spacingBottom}
			spacingTop={spacingTop}
			backgroundColor={backgroundColor}
		>
			<ul className={clsx('grid', 'auto-rows-fr', 'grid-cols-1', 'lg:grid-cols-3')}>
				{renderedArticles && renderedArticles.length && (
					<>
						{renderedArticles.map((article) => {
							if (!article) {
								return null;
							}

							return (
								<li key={article._key}>
									<ArticleCard article={article} headingLevel={2} />
								</li>
							);
						})}
					</>
				)}
			</ul>
			{hasMore && <button onClick={loadMore}>Vis flere artikler</button>}
		</Container>
	);
};

export default ArticleListPaginated;
