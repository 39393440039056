import type {FC} from 'react';
import clsx from 'clsx';

import type {ContainerBackgroundColor, ContainerSpacing} from '@/components/container';
import {Container} from '@/components/container';
import type {KeyedArray} from '@/shared/types';

import type {CustomerDetails} from '../../shared/types';
import {CustomerCard} from '../customer-card';

interface Props {
	spacingTop?: ContainerSpacing;
	spacingBottom?: ContainerSpacing;
	customers: KeyedArray<CustomerDetails>;
	backgroundColor?: ContainerBackgroundColor;
}

export const CustomerList: FC<Props> = ({
	spacingBottom,
	spacingTop,
	customers,
	backgroundColor,
}) => {
	return (
		<Container
			spacingBottom={spacingBottom}
			spacingTop={spacingTop}
			backgroundColor={backgroundColor}
		>
			<div>
				<ul className={clsx('grid', 'gap-16')}>
					{customers && (
						<>
							{customers.map((customer, index) => {
								return (
									<li key={customer._key}>
										<CustomerCard
											heading={customer.heading}
											imageAlignment={index % 2 === 0 ? 'right' : 'left'}
											imageWeb={customer.imageWeb}
											introduction={customer.introduction}
											href={customer.href}
										/>
									</li>
								);
							})}
						</>
					)}
				</ul>
			</div>
		</Container>
	);
};
