import type {FC, PropsWithChildren} from 'react';

import {type ImageWeb} from '@/components/image/types';
import {imageWebFromImageWebSchema} from '@/sanity/components/image/query';
import {PortableText} from '@/sanity/components/portable-text';
import {resolveLinkHref} from '@/shared';
import type {KeyedArray} from '@/shared/types';

import {
	featuredCardPortableTextOverrides,
	type FeaturedCardProps,
} from '../components/featured-card';
import {FeaturedCardList} from '../components/featured-card-list';

import type {FeaturedCardListFragment} from './query';

type Props = PropsWithChildren & {
	data: FeaturedCardListFragment;
};

export const FeaturedCardListContainer: FC<Props> = ({data}) => {
	const cards: KeyedArray<FeaturedCardProps> = [];

	if (data.cards) {
		for (const card of data.cards) {
			let image: ImageWeb | undefined;
			if (card.icon?.asset?._ref) {
				image = imageWebFromImageWebSchema(card.icon);
			}

			cards.push({
				_key: card._key,
				href: resolveLinkHref(card),
				title: card.title ?? 'Mangler tittel...',
				imageWeb: image,
				headingLevel: data.heading ? 3 : 2,
				tagline: card.tagline,
				targetBlank: card.targetBlank,
				children: card.description && (
					<PortableText
						blocks={card.description}
						overrideClassNames={featuredCardPortableTextOverrides}
					/>
				),
			});
		}
	}

	return (
		<FeaturedCardList
			spacingBottom={data.layout?.spacingBottom}
			spacingTop={data.layout?.spacingTop}
			heading={data.heading}
			backgroundColor={data.backgroundColor}
			cards={cards}
		/>
	);
};
