import {groq} from 'next-sanity';

import type {KeyedArray} from '@/shared/types';

import type {CustomerListSelectionSchema} from '../../sanity/customer-list-selection-container/schema';
import type {CustomerInCardFragment} from '../../sanity/query';
import {
	CUSTOMER_IN_CARD_FRAGMENT,
	customerInCardFragmentToCustomerDetails,
} from '../../sanity/query';
import type {CustomerDetails} from '../../shared/types';

export const CUSTOMER_LIST_SELECTION_FRAGMENT = groq`
	...,
	customers[]-> {
		${CUSTOMER_IN_CARD_FRAGMENT},
	}
`;

export type CustomerListSelectionFragment = Omit<CustomerListSelectionSchema, 'customers'> & {
	_type: 'customerListSelection';
	customers?: (CustomerInCardFragment | null)[];
};

export const customerListFromCustomerListSelectionSection = (
	section: CustomerListSelectionFragment,
): KeyedArray<CustomerDetails> => {
	const customers: KeyedArray<CustomerDetails> = [];

	if (!section.customers) {
		return customers;
	}

	for (const customerInCard of section.customers) {
		if (customerInCard) {
			const customer = customerInCardFragmentToCustomerDetails(customerInCard);
			if (customer) {
				customers.push({
					_key: customerInCard._id,
					...customer,
				});
			}
		}
	}

	return customers;
};
